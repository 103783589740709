import { domain } from './var'

const main = {
    getBucketList                 : `${domain}/crm/site/bucketList`, // 获取bucket
    getTicketSignature            : `${domain}/crm/mp/getTicketSignature`, // GET 获取签名
    userinfo                      : `${domain}/userinfo`, // GET
    workerChangeUser              : `${domain}/workerChangeUser`, // 切换账号
    getUserInfo                   : `${domain}/crm/mp/getUserInfo`, // GET
    getContractList               : `${domain}/crm/mp/getContractList`, // GET 合同列表
    getChangeRecords              : `${domain}/crm/mp/getChangeRecords`, // GET  变更记录
    getCustomerSelectMaterialList : `${domain}/crm/mp/getCustomerSelectMaterialList`, // GET  获取客户选材列表
    customerConfirmMaterial       : `${domain}/crm/mp/customerConfirmMaterial`, // POST 确认选材
    getSelectMaterialChangeRecords: `${domain}/crm/mp/getSelectMaterialChangeRecords`, // GET 选材记录
    getContractInfo               : `${domain}/crm/mp/getContractInfo`, // GET 合同详情
    bindAuthCode                  : `${domain}/crm/mp/bindAuthCode`, // POST 绑定授权码
    sendVerifyCode                : `${domain}/crm/mp/sendVerifyCode`, // POST 发送验证码
    bindCustomer                  : `${domain}/crm/mp/bindCustomer`, // POST 绑定手机号
    getWorkTypeList               : `${domain}/crm/worker/getWorkTypeList`, // POST 工种
    workerLogout                  : `${domain}/crm/worker/workerLogout`, // 退出
    workerRegister                : `${domain}/workerRegister`, //  注册
    sendVerificationCode          : `${domain}/sendVerificationCode`, // 验证码
    workerLogin                   : `${domain}/workerLogin`, // 登录
    mine                          : `${domain}/crm/worker/mine`, // 我的v
    checkAuthentication           : `${domain}/crm/worker/checkAuthentication`, //
    getForemanList                : `${domain}/crm/site/getForemanList`, // 工长列表
    getContractingInfo            : `${domain}/crm/site/getContractingInfo`, // 获取工程发包详情

    getSupervisor: `${domain}/admin/position/getSupervisor`, // 监理列表

    getWorkerSiteList   : `${domain}/crm/site/getWorkerSiteList`, // 工地列表
    getWorkerSiteDetail : `${domain}/crm/site/getWorkerSiteDetail`, // 工地详情
    reportProgressRecord: `${domain}/crm/site/reportProgressRecord`, // 上传进度
    getMaterialList     : `${domain}/crm/site/getMaterialList`, // 材料订单和详情
    confirmOrder        : `${domain}/crm/site/confirmOrder`, // 订单确认
    confirmReceipt      : `${domain}/crm/site/confirmReceipt`, // 确认收货
    confirmStartSites   : `${domain}/crm/site/confirmStartSites`, // 确认开工
    getSiteNodeList     : `${domain}/crm/site/getSiteNodeList`, //

    assumeRole: `${domain}/alibaba/assumeRole`, // GET 获取sts.AccessKeyId
    getOpenId : `${domain}/getOpenId`, // GET

    getAloneCategoryList            : `${domain}/crm/site/management/getAloneCategoryList`, // 获取开启单独下单的辅材分类列表
    getAuxiliaryMaterialCategoryList: `${domain}/crm/site/management/getAuxiliaryMaterialCategoryList`, // 获取辅材分类
    getH5AuxiliaryMaterialList      : `${domain}/crm/site/management/getH5AuxiliaryMaterialList`, // 获取辅材列表
    createH5Order                   : `${domain}/crm/site/management/createH5Order`, // 辅材未正式下单(申请下单)
    getH5ApplyAuxiliaryMaterialList : `${domain}/crm/site/management/getH5ApplyAuxiliaryMaterialList`, // 获取辅材申请下单材料详情
    createRefundOrder               : `${domain}/crm/site/management/createRefundOrder`, // 开退补单
    addShoppingCart                 : `${domain}/crm/site/management/addShoppingCart`, // 添加购物车
    getShoppingCartList             : `${domain}/crm/site/management/getShoppingCartList`, // 购物车列表
    delShoppingCart                 : `${domain}/crm/site/management/delShoppingCart`, // 删除/清空购物车
    getShoppingCartInfo             : `${domain}/crm/site/management/getShoppingCartInfo`, // 获取购物车详情
    revokeAuxOrder                  : `${domain}/crm/site/management/revokeAuxOrder` // 撤销辅材下单

}
export default main
